import { AxiosError } from "axios";
import { IMiddleResponse } from "./middlewareTypes";

export enum DashboardType {
  DASHBOARD_PENDING = "DASHBOARD_PENDING",
  DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS",
  DASHBOARD_ERROR = "DASHBOARD_ERROR",
}

export interface IParamDashboard {
  StartDate: string;
  EndDate: string;
  Range: string;
  TransactionType: string;
  Status: string;
}

export interface IParamDashboardById {
  Id?: string;
}

export interface IDashboardDataMaster {
  Amount: number;
  DisbursId: string;
  Email: string;
  ErrorMessage: string;
  Fee: number;
  Id: string;
  IsError: boolean;
  Name: string;
  NetAmount: number;
  NoteReq: string;
  PhoneNumber: string;
  TopUpStatus: number;
}

export interface IDashboardResponse {
  code: number;
  status: string;
  message: string;
  data: any;
  paginate: {
    page: number;
    total_page: number;
    total_record: number;
    limit: number;
  };
}

export interface IDashboardResponseMaster {
  OrderTypeSummaries: any;
  StatusData: any;
  TransactionData: any;
}

export interface IDownloadTemplate {
  Date: string;
}

export interface IUploadPreview {
  Base64Value: string;
  fileName: string;
  categoryId: string;
}

export interface IApprovalData {
  Note: string;
  Id: string;
  Action: number;
}

export interface IUserDisburseData {
  Phone: string;
}

export interface IMiddlewareBodyDashboardIn {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "webdashboard";
  SubjectIdentifier: "dashboard";
  Action: "get_dashboard";
  ResourceType: "data";
  Resource: "data";
  ResourceId: "data";
  UserId: string;
  Date: string;
  ScopePlatform: "private";
  Platform: "qoin-apps";
  Data: IParamDashboard;
}

export interface IParamApprovalDashboard {
  Action: 1 | 2;
  MasterFeeId: string;
}

export interface IDetailsRowExcel {
  Id: string;
  DisbursId: string;
  PhoneNumber: string;
  Name: string;
  Email: string;
  Amount: number;
  Fee: number;
  NetAmount: number;
  NoteReq: string;
  NoteReject: string;
  ApprovalStatus: number;
  TopUpStatus: number;
  IsError: boolean;
  ErrorMessage: string;
}

export interface IParamCreate {
  Id: string;
  ClientId: number;
  CategoryId: string;
  AccountHolderSource: string;
  Type: number;
  FileName: string;
  ReferenceNumber: string;
  TotalTrx: number;
  TotalTrxAmount: number;
  DashboardAmount: string;
  IsRecurring: boolean;
  Details: IDetailsRowExcel[];
  Schedule?: {
    ScheduleType: string;
    ScheduleTime: {
      Time: string;
    };
  };
}

export interface IMiddlewareBodyDashboardOut {
  TicketId: string;
  Subject: "ewallet";
  SubjectType: "webdashboard";
  SubjectIdentifier: "dashboard";
  Action: "get_dashboard";
}

export interface ITemplateResultResp {
  Data: {
    Base64Value: string;
    FileName: string;
  };
  Ticket: string;
  Code: number;
  Status: string;
  Errors: string;
  RequestId: string;
  Command: string;
  ModuleName: string;
}

export interface IMiddlewareResponseDashboardOut {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "webdashboard";
  SubjectIdentifier: "dashboard";
  Action: "get_dashboard";
  ResourceType: null;
  Resource: null;
  ResourceId: null;
  UserId: string;
  Date: string;
  Result: IDashboardResponseMaster;
  Status?: string;
  StatusCode?: number;
}

interface GetDashboardAction {
  type: DashboardType.DASHBOARD_SUCCESS;
  payload: {
    data: IMiddlewareResponseDashboardOut | IMiddleResponse | null;
  };
}

interface SetLoadingDashboardAction {
  type: DashboardType.DASHBOARD_PENDING;
  payload?: {
    data: any;
  };
}

interface SetErrorDashboardAction {
  type: DashboardType.DASHBOARD_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IDashboardState {
  data: IMiddlewareResponseDashboardOut | IMiddleResponse;
  isLoading: boolean;
  error: AxiosError;
}

export type DashboardAction =
  | GetDashboardAction
  | SetLoadingDashboardAction
  | SetErrorDashboardAction;
